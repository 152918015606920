<template>
  <div>
    <div class="backgroundSWV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarger"
          >Stellenwertverständnis – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller">
          Welche Facetten des Hintergrundwissens konnten beim
          Stellenwertverständnis ausgemacht werden?
        </v-card-title>
        <v-card-text>
          Das Hintergrundwissen im Bereich Stellenwertverständnis lässt sich
          aufgliedern in die drei Facetten:
          <ul>
            <li>Bündeln und Entbündeln</li>
            <li>Stellenwert und Zahlenwert</li>
            <li>Darstellungen vernetzen</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem auch für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau beispielsweise Probleme auftreten
          und daran anschließend eine passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie im Bereich der Diagnose und Förderung im
          Stellenwertverständnis?</v-card-title
        >
        <v-card-text>
          In drei Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          <ul>
            <li>
              typische Fehler im Bereich des Stellenwertverständnis
              nachzuvollziehen und nach Gemeinsamkeiten zu ordnen,
            </li>
            <li>
              Fehler im Bereich des Stellenwertverständnis zu diagnostizieren
              und
            </li>
            <li>
              eine Förderaufgabe für ein Kind diagnosegeleitet auszuwählen.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <AppBottomNavSWV
      back="/stellenwertverstaendnis/hintergrundwissen/darstellungswechsel"
      next="/stellenwertverstaendnis/diagnose-und-foerderung"
    />
  </div>
</template>

<script>
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
export default {
  components: {
    AppBottomNavSWV,
  },
};
</script>

<style scoped>
#app .titleLarger {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
